app:
  description: We build tailor-made web3 projects.
navigation:
  home: 'Home '
  services: 'Services '
  about: 'About '
  contact: 'Contact '
footer:
  menu: 'Menu '
  solutionDesign: 'Solution Design '
  webDevelopment: 'Web Development '
  mobileDevelopment: 'Mobile Development '
  web3Development: 'Web3 Development '
  information: 'Information '
  ICO: 'Business ID '
  DIC: 'Tax ID '
  copyright: 'Copyright '
  rightsReserved: 'All Rights Reserved '
  state: 'Czech Republic '
contact:
  title: 'Let''s schedule a consultation '
  write: 'Email Us '
  call: 'Call Us '
  form:
    name: 'Your name '
    email: 'E-mail '
    phone: 'Phone number '
    subject: 'Subject '
    message: 'Message '
    send: 'Send Message '
  thank: 'Thank you '
  sent: 'Your message has been sent '
  reply: 'We will get back to you soon '
counter:
  title: 'How do we stand '
  subtitle: 'Our experience in numbers '
  experience: 'Years in the Industry '
  clients: 'Satisfied clients '
  webSolutions: 'Web Solutions '
  completeSolutions: 'Complete Solutions '
info:
  title1: We build tailor-made
  title2: ' '
  title3: 'web3 '
  title4: 'projects '
  title5: ''
  subtitle: 'Whether you are solving any problem or looking for the right partner
    to create a customized, complex solution. '
  cta: 'LET''S ARRANGE A CONSULTATION '
offers:
  title: 'What can we offer '
  subtitle: 'Customized software development '
  offer1:
    title: 'Complete solution '
    text: 'We will develop a complete solution design, from company processes to the
      overall technical solutions including prototype '
  offer2:
    title: 'Web development '
    text: 'We have been creating web solutions for over 15 years and use the latest
      technologies '
  offer3:
    title: 'Mobile development '
    text: 'We have been creating mobile applications for over 5 years, we will create
      a suitable application for Android and iOS '
  offer4:
    title: 'Web3 development '
    text: 'We have been actively involved in decentralization for over 3 years, creating
      smart contracts and web-based solutions '
partners:
  title: 'Satisfaction must be mutual '
  subtitle: 'Selected clients and partners '
services:
  title: 'What are we really good at '
  subtitle1: 'We create complete solutions '
  subtitle2: 'without '
  subtitle3: 'limits '
  creativity:
    title: 'Creativity '
    text: 'Creativity is our strength. We come up with solutions from A to Z '
  modern:
    title: 'Modern Approach '
    text: 'We always try to keep up with the latest trends and tools '
  modularity:
    title: 'Modularity '
    text: 'We build our solutions so that they can be developed or changed at any
      time '
  technology:
    title: 'Technologies '
    text: 'We always choose appropriate technologies for development in order to achieve
      the most efficient solution '
team:
  title: 'Small, yet flexible '
  subtitle: 'Our Team '
