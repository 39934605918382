import revive_payload_client_jXubWt4fjh from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_rgpjy64tmdbigmg6yms5rl736y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ft2Zxib1JQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_rgpjy64tmdbigmg6yms5rl736y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bA6hPcMsjW from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_rgpjy64tmdbigmg6yms5rl736y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_M5Gxm0pa2r from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.4_rollup@4.18.0_vue@3.4.32_typescript@5.4.5_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import payload_client_7SWuLKhYP9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_rgpjy64tmdbigmg6yms5rl736y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KtZuv6jpUa from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_rgpjy64tmdbigmg6yms5rl736y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8PHPx4nNxw from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_rgpjy64tmdbigmg6yms5rl736y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6sau39rznG from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_rgpjy64tmdbigmg6yms5rl736y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_FuOFLVCZmj from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.18.0_typescript@5.4.5_vue@3.4.32_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_mSZOJvrtvk from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_rgpjy64tmdbigmg6yms5rl736y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_PWYFcSGncP from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.4_rollup@4.18.0_vue-router@4.4.0_vue@3.4.32_typescrip_lzkmzvuuxtyyyibv7a5lsp6x7q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_LVEqS4SfBp from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.4_rollup@4.18.0_vue-router@4.4.0_vue@3.4.32_typescrip_lzkmzvuuxtyyyibv7a5lsp6x7q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
export default [
  revive_payload_client_jXubWt4fjh,
  unhead_ft2Zxib1JQ,
  router_bA6hPcMsjW,
  sentry_client_M5Gxm0pa2r,
  payload_client_7SWuLKhYP9,
  navigation_repaint_client_KtZuv6jpUa,
  check_outdated_build_client_8PHPx4nNxw,
  chunk_reload_client_6sau39rznG,
  plugin_vue3_FuOFLVCZmj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mSZOJvrtvk,
  composition_PWYFcSGncP,
  i18n_LVEqS4SfBp
]