app:
  description: Vytváříme projekty web3 na míru.
navigation:
  home: Úvod
  services: Služby
  about: O Nás
  contact: Kontakt
footer:
  menu: Menu
  solutionDesign: Návrh řešení
  webDevelopment: Webový vývoj
  mobileDevelopment: Mobilní vývoj
  web3Development: Web3 vývoj
  information: Informace
  ICO: IČO
  DIC: DIČ
  copyright: Copyright
  rightsReserved: All Rights Reserved
  state: Česká republika
contact:
  title: Domluvme si nezávaznou konzultaci
  write: Pište
  call: Volejte
  form:
    name: Vaše jméno
    email: E-mail
    phone: Tel. číslo
    subject: Předmět
    message: Zpráva
    send: Odeslat zprávu
  thank: Děkujeme
  sent: Vaše zpráva byla odeslána
  reply: Budeme vás kontaktovat hned, jak to bude možné
counter:
  title: Jak jsme na tom
  subtitle: Naše zkušenosti v číslech
  experience: Let v oboru
  clients: Spokojených klientů
  webSolutions: Webových řešení
  completeSolutions: Kompletních řešení
info:
  title1: 'Vyvíjíme '
  title2: web3
  title3: ' '
  title4: projekty
  title5: na míru
  subtitle: Ať už řešíte jakýkoliv problém, či hledáte správného partnera pro tvorbu
    složitého řešení na míru.
  cta: DOMLUVME SI NEZÁVAZNOU KONZULTACI
offers:
  title: Co můžeme nabídnout
  subtitle: Vývoj softwaru na míru
  offer1:
    title: Kompletní řešení
    text: Zpracujeme kompletní návrh řešení, od firemních procesů až po celková technická
      řešení včetně prototypu
  offer2:
    title: Webový vývoj
    text: Tvorbou webových řešení se zabýváme přes 15 let, využíváme nejmodernější
      technologie
  offer3:
    title: Mobilní vývoj
    text: Tvorbou mobilních aplikací se zabýváme přes 5 let, vytvoříme vhodnou aplikaci
      pro Android i iOS
  offer4:
    title: Web3 vývoj
    text: Decentralizací se aktivně zabýváme přes 3 roky, vytvoříme smart kontrakty
      i webové řešení
partners:
  title: Spokojenost musí být oboustranná
  subtitle: Vybraní klienti a partneři
services:
  title: V čem jsme opravdu dobří
  subtitle1: Vytváříme kompletní řešení
  subtitle2: bez
  subtitle3: hranic
  creativity:
    title: Kreativita
    text: Kreativita je naše silná stránka. Vymyslíme řešení od A do Z
  modern:
    title: Moderní přístup
    text: Vždy se snažíme držet krok s nejmodernějšími trendy a nástroji
  modularity:
    title: Modularita
    text: Naše řešení stavíme tak, aby bylo kdykoli možné je rozvíjet či měnit
  technology:
    title: Technologie
    text: Pro vývoj volíme vždy vhodné technologie tak, abychom dosáhli co nejefektivnějšího
      řešení
team:
  title: Malý, ale za to pružný
  subtitle: Náš tým
